import * as React from "react"

const Header = () =>
    <div className="space-y-1 bg-white p-4">
        <h2 className="text-xl text-gray-500 font-thin text-center tracking-widest">REPHORM PRESENTS</h2>
        <h1 className="text-7xl text-cyan-400 font-bold text-center">SIMON HAIGH</h1>
        <h3 className="text-2xl text-gray-500 font-regular text-center">Freelance Experience and Graphic Designer</h3>
        <h3 className="text-lg text-black font-medium text-center">si@rephorm.com.au</h3>
        <a className="text-lg text-cyan-600 font-medium block text-center" href="https://www.linkedin.com/in/sihaigh/" target="blank">LinkedIn profile</a>
    </div>
export default Header;